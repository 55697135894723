import { chunk } from 'lodash';
import { mapState, mapActions } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    computed: {
        ...mapState({
            licensesItems: (state) => state.licenses.data,
            productItems: (state) => state.products.items,
            internalProductId: (state) => state.pages?.page?.product_id || null,
        }),
    },

    methods: {
        ...mapActions({
            generateSessionURL: 'cleverbridge/get',
        }),

        findCleverBridgeCart(slice, settings) {
            const licenses = this.recursiveSearch(slice || {}, 'cleverBridgeCartID').flat(2);
            const cleverBridgeCartList = licenses.map((license) => license.cleverBridgeCartID).flat(2);
            return this.getLicenses({ ...settings, cleverBridgeCartList });
        },

        productCTA(settings) {
            const type = settings?.isPurchase ? 'purchasePageCTA' : 'productPageCTA';
            const productId = settings?.productId || this.internalProductId;
            return this.productItems[productId]?.parameters?.common?.[type] || {};
        },

        async getLicenses(settings) {
            const locale = this.$route.params.locale || LOCALE_DEFAULT;
            await this.$store.dispatch('locales/getLocaleData', { locale });

            const PAGINATE_SIZE = 50;
            const payload = {
                locale,
                currency: this.productCurrency,
                paginate: { number: 1, size: PAGINATE_SIZE },
            };

            const pageSettingsCTA = this.productCTA(settings);
            const license = { ...pageSettingsCTA, ...settings };

            const coupon = license?.promo?.coupon || null;

            const licenses = license?.cleverBridgeCartList || [license.cleverBridgeCartID] || [];
            const listWithPromo = (!license.doNotApplyPromo && license?.promo?.cleverBridgeCartList) || [];
            const listWithOutPromo = licenses.filter((i) => !listWithPromo.includes(i));

            const promisesWithPromo = chunk(listWithPromo.filter(Number), PAGINATE_SIZE)
                .map((cleverbridgeIDs) => this.$store.dispatch('licenses/getList', { ...payload, cleverbridgeIDs, coupon }));

            const promisesWithOutPromo = chunk(listWithOutPromo.filter(Number), PAGINATE_SIZE)
                .map((cleverbridgeIDs) => this.$store.dispatch('licenses/getList', { ...payload, cleverbridgeIDs }));

            await Promise.all([...promisesWithPromo, ...promisesWithOutPromo]);
        },

        async initSettingsCTA(list) {
            const settings = (list || []).filter((item) => item?.productPageCTA?.productId);
            await Promise.all(settings.map((product) => this.getLicenses(product.productPageCTA)));
        },

        checkPromo(settings) {
            if (!settings?.cleverBridgeCartList?.length) return false;
            const comparison = (i) => i.discount_absolute && this.hasLicensePromo({
                cleverBridgeCartID: i.cleverbridge_id,
                promo: settings,
            });
            return Boolean(this.licensesItems.find(comparison));
        },

        recursiveSearch(obj, searchKey, results = []) {
            Object.keys(obj || {}).forEach((key) => {
                if (key === searchKey) {
                    results.push(obj);
                    return;
                }
                if (typeof obj[key] === 'object') {
                    this.recursiveSearch(obj[key], searchKey, results);
                }
            });
            return results;
        },
    },
};
