export const availableInBuilder: string[] = [
    's-anchor-menu',
    's-asset-cards',
    's-awards',
    's-award-compact',
    's-award-highlight',
    's-awards-ribbon',
    's-cards-highlight',
    's-cards-product',
    's-cards-small',
    's-cards-small-slider',
    's-cards-support',
    's-cards-with-icon',
    's-compete-page',
    's-cta',
    's-cta-with-image',
    's-cta-discount-code',
    's-cta-simple',
    's-customizable-header',
    's-download',
    's-event-countdown',
    's-faq',
    's-features-large',
    's-features-small',
    's-header-lp-three-products',
    's-header-message',
    's-header-product-new',
    's-header-simple',
    's-insertion',
    's-integrations',
    's-intro',
    's-message-highlights',
    's-numbers',
    's-product-reviews',
    's-requirements',
    's-requirements-combo',
    's-resources',
    's-schema-switcher',
    's-site-search',
    's-subscription',
    's-tabs',
    's-value-cards',
    's-video-featured',
];
