import { merge } from 'lodash';

export default {
    props: {
        translations: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        l10n() {
            return merge({}, this.$attrs, this.$props, this.$props.translations);
        },
    },
};
