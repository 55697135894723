import { mapActions, mapState } from 'vuex';
import { tiersMap } from '@core/slices/pages/express-signup-sales/constants.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import formUtils from '@utils/form.js';

export default {
    data() {
        return {
            isVisibleSURL: false,
        };
    },

    computed: {
        ...mapState({
            productItems: (state) => state.products.items,
            productID: (state) => state.pages?.page.product_id,
        }),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        language() {
            return this.locale.split('-').shift();
        },

        pageSettings() {
            return this.settings || this.page?.settings || {};
        },

        pageOptionsSURL() {
            return this.pageSettings?.optionsSURL || this.pageSettings?.page?.optionsSURL || {};
        },
        coupons() {
            const product = this.productItems?.[this.productID] || {};
            return product.parameters?.common?.cleverbridge?.coupons || [];
        },
        activeCoupon() {
            return this.coupons.find((item) => item.coupon === this.coupon) || {};
        },
        setExpressSignupCurrency() {
            const query = this.queryStringData;
            const passedCurrency = query.currency || localStorage.getItem('trial_es_currency');
            const list = {
                'es-mx': 'MXN',
                'ko-kr': 'KRW',
            };
            return passedCurrency || list[this.locale] || this.productCurrency || 'USD';
        },

        setExpressSignupCardId() {
            const passedPrice = this.queryStringData?.price || localStorage.getItem('trial_es_price');
            const passedCurrency = this.queryStringData?.currency || localStorage.getItem('trial_es_currency');
            return Object.keys(tiersMap).find((key) => {
                if (tiersMap[key][passedCurrency] === parseInt(passedPrice, 10)) return key;
                return false;
            });
        },

        payloadPartnerSignupCloud() {
            const cleverbridgeSettings = this.form.flow?.cleverbridge || {};
            const shopId = cleverbridgeSettings.shopId || 1794;
            const cart = cleverbridgeSettings.cart || 251542;

            const utmLabels = formUtils.labelsUTMCleverbridge();
            utmLabels['x-utm_medium'] = utmLabels['x-utm_medium'] || 'trial';
            utmLabels['x-utm_source'] = utmLabels['x-utm_source'] || `acronis-${this.locale}`;

            return {
                shopId,
                targetPathname: cleverbridgeSettings.targetPathname || 'purl-acp-nt',
                params: {
                    'x-adminemail': this.userData.email,
                    'x-adminfirstname': this.userData.first_name,
                    'x-adminlastname': this.userData.last_name,
                    'x-company': this.userData.company,
                    'x-country': this.userData.country,
                    'x-currency': cleverbridgeSettings.currency || this.productCurrency,
                    'x-deliveryPostalcode': this.userData.postal_code,
                    'x-deliveryStateId': this.userData.state,
                    'x-distributor': this.userData.distributor,
                    'x-distributorid': this.userData.distributor_id,
                    'x-endpointsrange': this.userData.endpointsrange,
                    'x-language': this.language || 'en',
                    'x-locale': this.locale,
                    'x-location': this.userData.dc_location,
                    'x-partnerIsLarge': this.userData.partnerIsLarge,
                    'x-phonenumber': this.userData.phone,
                    'x-webuid': this.guid,
                    AllowToChangeCart: false,
                    cart,
                    cfg: 'design2022_ir',
                    country: this.userData.country,
                    deliveryCompany: this.userData.company,
                    deliveryEmail: this.userData.email,
                    deliveryFirstname: this.userData.first_name,
                    deliveryLastname: this.userData.last_name,
                    deliveryPostalcode: this.userData.postal_code,
                    deliveryStateId: this.userData.state,
                    language: this.language || 'en',
                    locale: this.locale,
                    ...utmLabels,
                },
            };
        },

        payloadExpressSignup() {
            const language = this.locale === 'zh-tw' ? 'zg' : this.language;
            const aan = localStorage.getItem('trial_es_aan') || null;
            const optId = localStorage.getItem('trial_es_opportunity_id') || null;
            return {
                shopId: 837,
                targetPathname: 'purl-express-partner-signup',
                params: {
                    'x-aan': aan,
                    'x-adminemail': this.userData.email,
                    'x-adminfirstname': this.userData.first_name,
                    'x-adminlastname': this.userData.last_name,
                    'x-country': this.userData.country,
                    'x-currency': this.setExpressSignupCurrency,
                    'x-language': language,
                    'x-locale': this.locale,
                    'x-location': this.userData.dc_location,
                    'x-opp_id': optId,
                    'x-phonenumber': this.userData.phone,
                    'x-webuid': this.guid,
                    cart: this.setExpressSignupCardId || 240353,
                    country: this.userData.country,
                    currency: this.setExpressSignupCurrency,
                    deliveryCompany: this.userData.company,
                    deliveryEmail: this.userData.email,
                    deliveryFirstname: this.userData.first_name,
                    deliveryLastname: this.userData.last_name,
                    language,
                    locale: this.locale,
                    ...formUtils.labelsUTMCleverbridge(),
                },
            };
        },

        payloadExpressSignupTrialLicensing() {
            const language = this.locale === 'zh-tw' ? 'zg' : this.language;
            return {
                shopId: 837,
                targetPathname: 'purl-express-partner-signup',
                params: {
                    'x-aan': this.form.aan,
                    'x-adminemail': this.form.email,
                    'x-adminfirstname': this.form.first_name,
                    'x-adminlastname': this.form.last_name,
                    'x-country': this.form.country || this.country,
                    'x-currency': this.setExpressSignupCurrency,
                    'x-language': language,
                    'x-locale': this.locale,
                    'x-tenant-uid': this.form.tenantId,
                    'x-webuid': this.guid,
                    cart: 245936,
                    country: this.form.country || this.country,
                    currency: this.setExpressSignupCurrency,
                    deliveryCompany: this.form.company,
                    deliveryEmail: this.form.email,
                    deliveryFirstname: this.form.first_name,
                    deliveryLastname: this.form.last_name,
                    language,
                    locale: this.locale,
                    ...formUtils.labelsUTMCleverbridge(),
                },
            };
        },
    },

    mounted() {
        window.addEventListener('pageshow', this.pageShow);
    },

    beforeDestroy() {
        window.removeEventListener('pageshow', this.pageShow);
    },

    methods: {
        ...mapActions({
            generateSessionURL: 'cleverbridge/get',
        }),

        pageShow(event) {
            if (event && event.persisted) {
                // The page is loading from a cache
                const html = document.getElementsByTagName('html')[0];
                html.style.display = 'none';
                window.location.reload();
            }
        },

        storePURL(segment) {
            const purl = `purl-${segment}-standard-us`;
            const product = this.productItems?.[this.productID] || {};
            return product?.parameters?.common?.cleverbridge?.purl || purl;
        },

        storeOptionsSURL(settings) {
            const page = document.location.href.replace(/\?.*/, '');
            const { shopId, segment, targetPathname, params } = settings;

            const utm = {};
            const labels = ['utm_campaign', 'utm_medium', 'utm_source', 'x-audience', 'x-tenant_id', 'x-wave'];
            labels.forEach((name) => {
                const value = sessionStorage.getItem(name);
                if (value) utm[name] = value;
            });

            const options = {
                shopId: shopId || 882,
                targetPathname: targetPathname || this.storePURL(segment),
                params: {
                    cart: '',
                    language: this.locale === 'pt-br' ? 'pb' : this.language,
                    'x-page': page,
                    'x-segment': segment || '',
                    ...params,
                    ...utm,
                    ...this.pageOptionsSURL,
                },
            };

            Object.keys(options.params).forEach((k) => {
                options.params[k] = options.params[k].toString().replace('LOCALE', this.locale);
            });

            // WEB-48276' - A/B Test Buy Block CTA
            const split48276 = this.split48276 || {};
            if (split48276.dimension) {
                options.params['x-source'] = split48276.variant === 'b' ? 'buttons_type' : 'dropdowns_type';
            }

            return options;
        },

        async redirectToCleverbridge(payload) {
            this.isVisibleSURL = true;
            const response = await this.generateSessionURL(payload);
            const param = Object.entries(payload.params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
            const defaultLink = `https://store.acronis.com/${payload.shopId}/${payload.targetPathname}?${param}`;
            window.location.assign(response?.data?.data || defaultLink);
        },
    },
};
