import Cookies from 'js-cookie';
import localeSelectorDataSource from '@core/mixins/locale-selector-data-source';
import locales from '@core/mixins/locales.js';
import { LOCALE_DEFAULT } from '@model/const/locales';

export default {
    data() {
        return {
            isLocaleSelectorShown: false,
            isMounted: false,
        };
    },

    mixins: [locales, localeSelectorDataSource],

    mounted() {
        // show ribbon only if there is no locale cookie and we are on default locale
        // save locale otherwise
        const userLocale = Cookies.get(this.LOCALE_COOKIE_NAME);

        const isRibbonHidden = !!this.page?.settings?.hide_locale_selector_ribbon;

        if (!isRibbonHidden) {
            if (this.locale === LOCALE_DEFAULT && !userLocale) {
                this.isLocaleSelectorShown = true;
            } else {
                this.storeLocaleInCookies(this.locale);
            }
        }

        this.isMounted = true;
    },

    async serverPrefetch() {
        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: this.localeSelectorDataSourceName, locale: LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-locale-selector-ribbon', locale: LOCALE_DEFAULT }),
        ]);
    },
};
