import { LOCALE_DEFAULT } from '@model/const/locales';

export const DEFAULT_LANGUAGE = 'English';
export const BANNERS_COUNT = 10;
export const ARCHIVE_EVENTS_COUNT = 6;
export const IN_PERSON_EVENTS_COUNT = 36;
export const WEBINARS_AND_VIRTUAL_COUNT = 36;
export const WEBINARS_AND_VIRTUAL_TO_SHOW = 6;
export const AUDIENCES_ID = {
    serviceProviders: 1,
    business: 2,
    resellers: 3,
    home: 4,
};
export const AUDIENCES_COUNT = 36;
export const CATEGORIES_COUNT = 36;
export const LANGUAGES_COUNT = 50;
export const DEFAULT_COUNTRY_ID = 238; // U.S.
export const OTHER_EVENTS_COUNT = 3;
export const VIDEOS_LIMIT = 3;
export const RESOURCES_LIMIT = 6;
export const LANGUAGES_MAP = {
    'Chinese (Simplified)': 'zh-cn',
    'Chinese (Traditional)': 'zh-tw',
    'Portuguese (Brazilian)': 'pt-br',
    Bulgarian: 'bg-bg',
    Czech: 'cs-cz',
    English: 'en-us',
    French: 'fr-fr',
    German: 'de-de',
    Italian: 'it-it',
    Japanese: 'ja-jp',
    Korean: 'ko-kr',
    Polish: 'pl-pl',
    Portuguese: 'pt-br',
    Spanish: 'es-es',
    Turkish: 'tr-tr',
};

export const SLICE_DATA_FOOTER = {
    sliceSettingsName: 's-events-footer',
    locale: LOCALE_DEFAULT,
    type: 'short',
    theme: 'light',
    hasLocaleSelector: false,
    hasBorderTopSocials: false,
};
