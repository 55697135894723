import { GOOGLE_MAP_API_KEY } from '@model/const/api-keys.ts';
import { LOCALE_DEFAULT } from '@model/const/locales';

export default {
    data() {
        return {
            map: null,
            isLoadedGoogleMaps: false,
        };
    },
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        language() {
            return this.locale.split('-').shift();
        },
    },
    mounted() {
        const googleMapsLoaded = new Promise((resolve) => {
            const params = 'loading=async&callback=GoogleMapsInit&libraries=marker';
            const src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&${params}&language=${this.language}`;
            const script = document.createElement('script');
            script.setAttribute('src', src);
            window.GoogleMapsInit = resolve;
            document.body.appendChild(script);
        });
        googleMapsLoaded.then(() => {
            this.addMap();
            this.addMarkers();
        });
    },
};
