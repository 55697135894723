import Cookies from 'js-cookie';
import { mapState } from 'vuex';
import { DEFAULT_COUNTRY_ID } from '@model/const/events.ts';

export default {
    async beforeMount() {
        if (this.$store.state.events.country?.id) return;

        const countryId = await this.getCountryFromGeoAPI();
        if (!countryId) return;

        await this.$store.dispatch('events/setCountry', { countryId, isConfirmed: false });
        this.$emit('onLocationSet');
    },

    computed: {
        ...mapState({ eventsStore: (state) => state.events }),

        countryId() {
            return this.eventsStore.country?.id || DEFAULT_COUNTRY_ID;
        },

        locale() {
            return this.$route.params.locale;
        },

        countries() {
            return this.$store.state.countries.items || [];
        },

        translations() {
            return this.$store.state.slices.items['events-interface-translations'] || {};
        },

        audiences() {
            return (this.eventsStore.audiences.items || []).map((item) => ({
                ...item,
                title: this.translations.audiences?.[item.title] || item.title,
            }));
        },

        categories() {
            return (this.eventsStore.categories.items || []).map((item) => ({
                ...item,
                title: this.translations.categoryNames?.[item.title] || item.title,
            }));
        },

        localeSelect() {
            const countryId = this.eventsStore.country?.id || null;
            return {
                isReady: countryId !== null,
                countries: this.countries,
                countryIdentifiedTitle: this.translations.countryIdentifiedTitle,
                changeCountryTitle: this.translations.changeCountryTitle,
                confirmCountryButtonTitle: this.translations.confirmCountryButtonTitle,
                countryChangedLabel: this.translations.countryChanged,
                countryId,
                isConfirmed: this.eventsStore.country?.isConfirmed,
                theme: this.theme,
            };
        },
    },

    methods: {
        getYoutubeIdFromUrl(url) {
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = url.match(regExp);
            return match && match[7].length === 11 ? match[7] : false;
        },

        videoDurationToTimeString(duration) {
            const durationNum = parseInt(duration, 10);
            const hours = Math.floor(durationNum / 3600);
            const minutes = Math.floor(durationNum / 60) % 60;
            const seconds = durationNum % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? `0${v}` : v))
                .filter((v, i) => v !== '00' || i > 0)
                .join(':');
        },

        async getCountryFromGeoAPI() {
            await this.$store.dispatch('geolocation/getGeoLocation');
            const location = this.$store.state.geolocation.location;

            if (!location) return null;

            const countries = this.$store.state.countries.items || [];
            const country = countries.find((item) => item.iso_code === location.data.country.code);

            if (!country) return null;

            return country.id;
        },

        setCountryCookie(countryID) {
            Cookies.set('events_country', countryID, {
                path: '/',
                expires: 30, // days
            });
        },
    },
};
