import { SECTION_ID_BLOG } from '@model/const/blog.ts';

export default {
    computed: {
        translations() {
            return this.$store.state.slices.items?.blog || {};
        },

        isBlog() {
            return this.sectionId === SECTION_ID_BLOG;
        },

        isOnEnLocale() {
            return this.$route.params.locale?.startsWith('en');
        },
    },

    methods: {
        getSectionName(id) {
            return id === SECTION_ID_BLOG ? 'blog' : 'cyber-protection-center';
        },

        getBlogPostFields(post, translations) {
            if (!post) return null;

            const sectionPath = this.getSectionName(post.section_id);

            return {
                ...post,
                minReadTitle: translations.minRead,
                link: `/${sectionPath}/posts/${post.slug}`,
            };
        },

        getAuthorFields(author) {
            if (!author) return null;

            const socials = [];
            if (author.link_linked_in) {
                socials.push({
                    to: author.link_linked_in,
                    glyph: 'linkedin2',
                });
            }
            if (author.link_twitter) {
                socials.push({
                    to: author.link_twitter,
                    glyph: 'twitter',
                });
            }
            if (author.link_website) {
                socials.push({
                    to: author.link_website,
                    glyph: 'language-select',
                });
            }

            return {
                ...author,
                socials,
                link: `/blog/authors/${author.slug}`,
            };
        },

        getNewsFields(item, locale = null) {
            if (!item) return null;

            const parentSection = this.getSectionName(item.section_id);
            const link = [locale, parentSection, 'posts', item.slug].filter(Boolean).join('/');
            return {
                ...item,
                link: `/${link}`,
            };
        },

        getCategoryFields(category, locale = null) {
            if (!category) return null;

            const parentSection = category.section_id === SECTION_ID_BLOG ? 'blog' : 'cyber-protection-center';
            const link = [locale, parentSection, 'categories', category.slug].filter(Boolean).join('/');
            return {
                id: category.id,
                title: category.title,
                link: `/${link}`,
            };
        },
    },
};
