import type { WorkdayAPIJob } from '@model/dto/workday-api-jobs';
import SchemaHandler from '@model/seo/schemaHandler';
import { JobPostingSchema, SchemaConfig } from '@model/seo/schemas';
import type { AugmentedStore } from '@model/vue-store/augmented-store';

const TWO_YEARS_MS = 62208000000;

export default class JobPostingHandler implements SchemaHandler {
    constructor(
        public readonly ctx: { $store: AugmentedStore },
        public readonly schemasConfig: SchemaConfig[],
    ) {
        // NO OP
    }

    getSchemaData(staticConfig: JobPostingSchema): Promise<JobPostingSchema[]> {
        return this.getRequiredFields(staticConfig);
    }

    async getRequiredFields(staticConfig: JobPostingSchema): Promise<JobPostingSchema[]> {
        const jobs = await this.ctx.$store.dispatch('workday/getAllJobs') as WorkdayAPIJob[];

        return jobs.map((job) => ({
            ...staticConfig,
            title: job.title,
            description: '~',
            datePosted: job.startDate,
            // a hacky solution that was discussed with Adrian
            validThrough: new Date(Date.now() + TWO_YEARS_MS).toISOString(),
            jobLocation: {
                '@type': 'Place',
                name: job.primaryLocation.descriptor,
                address: {},
            },
        }));
    }
}
