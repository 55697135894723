import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    computed: {
        phoneInputSettings() {
            return this.$store.state.slices.items?.['a-phone-number-input'] || null;
        },
    },
    methods: {
        onPhoneInputChange(event) {
            const field = ('phone_number' in this.form) ? 'phone_number' : 'phone';
            this.form[field] = event.number;
            this.form.phoneCountryCode = event.country;
        },
    },
    async serverPrefetch() {
        const locale = this.$route?.params.locale || LOCALE_DEFAULT;
        await this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale });
    },
};
