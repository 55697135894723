import { mapActions } from 'vuex';
import formHelper from '@utils/form.js';

export default {
    data() {
        return {
            instance: {
                event_id: null,
                email: null,
                osauid: null,
                gated_unlock_osauid: null,
            },
            ytTrack: {
                ytId: null,
                progress: 0,
                duration: 0,
                current: 0,
            },
            searchParams: null,
        };
    },
    beforeMount() {
        this.searchParams = new URL(document.location).searchParams;
    },
    methods: {
        ...mapActions({
            createLeadRequest: 'vroi/createLeadRequest',
            updateLeadRequest: 'vroi/updateLeadRequest',
        }),
        setYtTrackId(payload) {
            this.instance.event_id = payload;
            this.instance.gated_unlock_osauid = localStorage.getItem(`track_id_${payload}`);
            this.instance.email = localStorage.getItem(`track_email_${payload}`);
        },
        setYtEventData(payload) {
            this.instance.email = payload?.email || null;
            this.instance.gated_unlock_osauid = payload.osauid || null;
            localStorage.setItem(`track_id_${this.instance.event_id}`, this.instance.gated_unlock_osauid);
            localStorage.setItem(`track_email_${this.instance.event_id}`, this.instance.email);
        },
        setYtTrackData(payload) {
            this.ytTrack.progress = payload?.progress || 0;
            this.ytTrack.current = payload?.current || 0;
            this.ytTrack.duration = payload?.duration || 0;
            this.ytTrack.ytId = payload?.ytId || null;
            this.updateOrCreateTrack();
        },
        async updateOrCreateTrack() {
            if (!this.instance.email) return;
            if (!this.instance.osauid) {
                await this.ytTrackRequest('create');
                return;
            }

            try {
                await this.ytTrackRequest('update');
            } catch (error) {
                const status = error.response?.status;
                if (!status || ![409, 404].includes(status)) return;
                await this.ytTrackRequest('create');
            }
        },
        async ytTrackRequest(action = 'create') {
            const payload = {
                ...this.instance,
                ...this.ytTrack,
                form_id: 'events_video_progress',
                ck: formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken(),
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid: formHelper.getCIDval() || '[ERROR] Client ID not generated',
                ...formHelper.labelsUTM(),
                ...formHelper.sfdcCampaign(),
                ...formHelper.sfdcCampaignSmart(),
            };
            const updateOrCreate = action === 'update' ? this.updateLeadRequest : this.createLeadRequest;
            const response = await updateOrCreate(payload);
            this.instance.osauid = response.data.data.osauid;
        },
    },
};
