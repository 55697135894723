export function getNestedSlices(node: any): any[] {
    // TODO: this is more complicated than it should be.
    // make s-all-products use the same format as wrapper, tabs, side-menu
    const nested = node.name === 's-all-products'
        ? (node.data?.slicesData || []).flatMap((x: any) => x.slices)
        : (node.data?.slices || []);

    return nested.filter(Boolean);
}

// Recursive routine for dynamic slices resolution (including `.data.slices[*].name`).
// Used for dynamic slices resolution in pages view components.
export function resolvePageBodySlices(pageBody: any[]): string[] {
    const names = new Set<string>();

    // Only interested in dynamic slices, starting with `s-`
    const maybeAdd = (name: any) => {
        if (name?.startsWith('s-')) {
            names.add(name);
        }
    };

    const resolveRecursive = (node: any) => {
        maybeAdd(node.name);
        getNestedSlices(node).forEach(resolveRecursive);
    };

    pageBody.forEach(resolveRecursive);

    return Array.from(names);
}
