import { merge } from 'lodash';
import { LOCALE_DEFAULT } from '@model/const/locales';
import type { Synced } from '@model/synced/synced';
import { getNestedSlices } from './resolve-nested-slices';

// The idea here:
// - traverse through slices of the page body (nested)
// - check if any of them uses `dataFrom`
// - resolve the data with Synced
// - mutate the slice `data` to merge it with the resolved dataFrom
// ----
// NOTE: despite the mutation is in-place,
// it's safe since we do this right after the fetch in the page container
// ----
// NOTE: Synced is provided as an argument, because direct import gives us uninitalized module.
// This happens due to Vue server rendered being bundled and therefore is treated as a new module.
// This is not fixable so far, but things may become better with Vue 3 and Vite.
export async function resolveDataFrom(pageBody: any[], locale: string, Synced: Synced): Promise<any[]> {
    if (process.env.VUE_ENV !== 'server') {
        throw new Error('This method requires Synced, you can not use it on the client');
    }

    const doDataFromResolverRound = async (node: any) => {
        if (node.dataFrom) {
            const syncedData = await Synced.slicesData.query(node.dataFrom, locale, LOCALE_DEFAULT);
            const sliceData = node.data || {};
            node.data = merge({}, syncedData, sliceData); // eslint-disable-line
        }

        const nested = getNestedSlices(node);
        const nestedPromises = nested.map(doDataFromResolverRound);

        await Promise.all(nestedPromises);
    };

    const rootPromises = pageBody.map(doDataFromResolverRound);
    await Promise.all(rootPromises);

    return pageBody;
}
