import { type Result, ok, err } from './result';

export function sleep(dur: number): Promise<void> {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, dur));
}

export type BackoffOptions<T> = {
    // Max number of attempts
    retries: number,
    // If not provided, exponential backoff with base=10 will be used (1000ms, 10000ms, etc.)
    delayFn?: (i: number) => number,
    // A closure to perform an attempt
    worker: () => Promise<T>,
}

export async function backoff<T>(options: BackoffOptions<T>): Promise<Result<T>> {
    if (options.retries <= 0) {
        throw new Error('Retries must be a positive number');
    }

    const delayFn = options.delayFn ?? ((i: number) => 10 ** (i + 3));

    let lastError: Error | undefined;

    for (let i = 0; i < options.retries; i++) {
        /* eslint-disable no-await-in-loop */
        try {
            const res = await options.worker();
            return ok(res);
        } catch (error) {
            lastError = error as Error;
            await sleep(delayFn(i));
        }
        /* eslint-enable */
    }

    return err(lastError);
}
