/**
 * Button types
 *
 * @return {string[]}
 */

export const BORDER_RADIUS = [0, 2, 4, 8, 12, 16, 20, 24, 28];

export const LAYOUTS = ['right-icon', 'left-icon', 'no-icon', 'only-icon'];

export const TYPES = ['action', 'main', 'secondary', 'inverse', 'ghost'];

export const SIZES = ['large', 'medium', 'small', 'tiny', 'micro'];

export default {};
