// here is the reference to check a date-time format for any locale
// https://leap.hcldoc.com/help/topic/SSS28S_8.2.1/XFDL_Specification/i_xfdl_r_locale_quick_reference.html
import { tz } from '@date-fns/tz';
import { format as formatCustomDate, parseISO as parseISODate } from 'date-fns';
import {
    de,
    enGB,
    enUS,
    es,
    fr,
    id,
    ja,
    ko,
    pl,
    zhCN,
    zhTW,
    he,
    it,
    cs,
    hu,
    nl,
    sv,
    ptBR,
    ro,
    tr,
    bg,
    hi,
    th,
    vi,
// eslint-disable-next-line import/extensions
} from 'date-fns/locale';

type LinkOptions = {
    localeList: string[]
    localesConfig: { isoCode: string, language: string }[]
    urlTemplate: string
}

const LOCALE_DEFAULT = 'en-us';

const LOCALES_TIME_FORMAT_MAP = {
    'en-eu': {
        locale: enUS,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'MMMM d, yyyy, HH:mm' },
    },
    'en-gb': {
        locale: enGB,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'en-sg': {
        locale: enUS,
        format: { full: 'MMMM dd, yyyy', short: 'MMMM dd', time: 'MMMM dd, yyyy, HH:mm' },
    },
    'en-us': {
        locale: enUS,
        format: { full: 'MMMM dd, yyyy', short: 'MMMM dd', time: 'MMMM dd, yyyy, h:mm a' },
    },
    'id-id': {
        locale: id,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'pl-pl': {
        locale: pl,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'de-de': {
        locale: de,
        format: { full: 'dd. MMM yyyy', short: 'd MMMM', time: 'dd. MMM yyyy, HH:mm' },
    },
    'es-es': {
        locale: es,
        format: { full: "dd 'de' MMMM 'de' yyyy", short: "d 'de' MMMM 'de'", time: "dd 'de' MMMM 'de' yyyy, HH:mm" },
    },
    'es-mx': {
        locale: es,
        format: { full: "dd 'de' MMMM 'de' yyyy", short: "d 'de' MMMM 'de'", time: "dd 'de' MMMM 'de' yyyy, HH:mm" },
    },
    'fr-fr': {
        locale: fr,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'MMMM d, yyyy, HH:mm' },
    },
    'ja-jp': {
        locale: ja,
        format: { full: 'yyyy年M月d日(EEE)', short: 'M月d日', time: 'yyyy年M月d日(EEE) HH:mm' },
    },
    'ko-kr': {
        locale: ko,
        format: { full: 'PPP', short: 'd MMMM', time: 'PPP, HH:mm' },
    },
    'zh-cn': {
        locale: zhCN,
        format: { full: 'yyyy年M月d日', short: 'M月d日', time: 'yyyy年M月d日, HH:mm' },
    },
    'zh-tw': {
        locale: zhTW,
        format: { full: 'yyyy年M月d日', short: 'M月d日', time: 'yyyy年M月d日, HH:mm' },
    },
    'he-il': {
        locale: he,
        format: { full: 'd MMMM yyyy', short: 'd MMMM', time: 'd MMMM yyyy, HH:mm' },
    },
    'it-it': {
        locale: it,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'cs-cz': {
        locale: cs,
        format: { full: 'd. MMMM yyyy', short: 'd MMMM', time: 'd. MMMM yyyy, HH:mm' },
    },
    'hu-hu': {
        locale: hu,
        format: { full: 'yyyy. MMMM d.', short: 'MMMM d.', time: 'yyyy. MMMM d., HH:mm' },
    },
    'nl-nl': {
        locale: nl,
        format: { full: 'd MMMM yyyy', short: 'd MMMM', time: 'd MMMM yyyy, HH:mm' },
    },
    'sv-se': {
        locale: sv,
        format: { full: 'd MMMM yyyy', short: 'd MMMM', time: 'd MMMM yyyy, HH:mm' },
    },
    'pt-br': {
        locale: ptBR,
        format: { full: "d 'de' MMMM 'de' yyyy", short: "d 'de' MMMM 'de'", time: "d 'de' MMMM 'de' yyyy, HH:mm" },
    },
    'ro-ro': {
        locale: ro,
        format: { full: 'd MMMM yyyy', short: 'd MMMM', time: 'd MMMM yyyy, HH:mm' },
    },
    'tr-tr': {
        locale: tr,
        format: { full: 'd MMMM yyyy', short: 'd MMMM', time: 'd MMMM yyyy, HH:mm' },
    },
    'bg-bg': {
        locale: bg,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'hi-in': {
        locale: hi,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'th-th': {
        locale: th,
        format: { full: 'dd MMMM yyyy', short: 'd MMMM', time: 'dd MMMM yyyy, HH:mm' },
    },
    'vi-vn': {
        locale: vi,
        format: { full: "MMMM 'năm' y", short: "MMMM 'năm'", time: 'MMMM \'năm\' y, HH:mm' },
    },
};

export const LOCALE_TO_ENGLISH_MAP = {
    'bg-bg': 'en-eu',
    'cs-cz': 'en-eu',
    'de-de': 'en-eu',
    'en-eu': 'en-eu',
    'en-gb': 'en-gb',
    'en-sg': 'en-sg',
    'en-us': 'en-us',
    'es-es': 'en-eu',
    'es-mx': 'en-us',
    'fr-fr': 'en-eu',
    'he-il': 'en-eu',
    'hi-in': 'en-sg',
    'hu-hu': 'en-eu',
    'id-id': 'en-sg',
    'it-it': 'en-eu',
    'ja-jp': 'en-sg',
    'ko-kr': 'en-sg',
    'nl-nl': 'en-eu',
    'pl-pl': 'en-eu',
    'pt-br': 'en-us',
    'ro-ro': 'en-eu',
    'sv-se': 'en-eu',
    'th-th': 'en-eu',
    'tr-tr': 'en-eu',
    'vi-vn': 'en-sg',
    'zh-cn': 'en-sg',
    'zh-tw': 'en-sg',
};

export const LOCALE_TO_COUNTRY_MAP = {
    'de-de': 'Deutschland',
    'en-au': 'Australia',
    'en-eu': 'Europe',
    'en-gb': 'United Kingdom',
    'en-hk': 'Hong Kong',
    'en-in': 'India',
    'en-sg': 'Singapore',
    'en-us': 'United States',
    'es-es': 'España / Spain',
    'es-mx': 'México / Mexico',
    'fr-ca': 'Canada Français',
    'fr-fr': 'France',
    'id-id': 'Indonesian',
    'it-it': 'Italia / Italy',
    'ja-jp': '日本 / Japan',
    'ko-kr': '대한민국 / Korea',
    'pl-pl': 'Polska / Poland',
    'zh-cn': '中国 / China',
    'zh-tw': '台灣 / Taiwan',
    'bg-bg': 'Bulgaria',
    'cs-cz': 'Czech',
    'he-il': 'Israel',
    'hu-hu': 'Hungary',
    'hi-in': 'India',
    'pt-br': 'Brazil',
    'ro-ro': 'Romania',
    'tr-tr': 'Turkey',
    'vi-vn': 'Vietnam',
    'nl-nl': 'Netherlands',
    'th-th': 'Thailand',
    'sv-se': 'Sweden',
};

export const LOCALE_TO_LANGUAGE_MAP = {
    'en-us': 'English (US & Canada)',
    'en-eu': 'English (EU)',
    'en-gb': 'English (UK)',
    'en-sg': 'English (Singapore)',
    'id-id': 'Bahasa Indonesia',
    'pl-pl': 'Polski',
    'de-de': 'Deutsch',
    'fr-fr': 'Français',
    'it-it': 'Italiano',
    'he-il': 'עברית',
    'es-es': 'Español (Spain)',
    'es-mx': 'Español (Mexico)',
    'bg-bg': 'Български',
    'cs-cz': 'Čeština',
    'hu-hu': 'Magyar',
    'nl-nl': 'Dutch',
    'ja-jp': '日本語',
    'zh-cn': '简体中文',
    'zh-tw': '繁體中文',
    'ko-kr': '한국어',
    'hi-in': 'हिन्दी',
    'pt-br': 'Português (Brazil)',
    'ro-ro': 'Română',
    'tr-tr': 'Türkçe',
    'vi-vn': 'Tiếng Việt',
    'sv-se': 'Svenska',
};

/**
 * Retrieves the available locales excluding the current locale and 'es-mx'.
 *
 * @param list - The list of locales to filter.
 * @param currentLocale - The current locale for comparison.
 * @returns An array of available locales excluding the current locale and 'es-mx'.
 */
export function getAvailableLocales(list: { locale: string }[], currentLocale: string): string[] {
    const localeList = list?.map((item) => item.locale) || [];
    const hasEsEs = localeList.includes('es-es');
    const hasEsMx = localeList.includes('es-mx');
    let filteredLocales = localeList.filter((locale) => !locale.startsWith('en'));
    if (hasEsEs && hasEsMx) {
        // If both `es-es` and `es-mx` are present, only include `es-es`
        filteredLocales = filteredLocales.filter((locale) => locale !== 'es-mx');
    }

    const currentEnglishLocale = localeList.find((locale) => locale === LOCALE_TO_ENGLISH_MAP[currentLocale]);

    return [currentEnglishLocale, ...filteredLocales]
        .filter(Boolean)
        .filter((locale) => locale !== currentLocale);
}

/**
 * Formats a given timestamp to a locale-specific date string.
 * If the timestamp is falsy, returns null.
 *
 * @param timestamp - The timestamp to format.
 * @param locale - The locale to use for formatting.
 * @param format - Preset name used for formatting or custom format string
 * @returns The formatted date string, or null if the timestamp is falsy.
 */
export function formatToLocaleDate(timestamp: string, locale: string, format: string = 'full'): string | null {
    if (!timestamp) return null;

    const localeOptions = LOCALES_TIME_FORMAT_MAP[locale] || LOCALES_TIME_FORMAT_MAP[LOCALE_DEFAULT];
    const dateFormat = localeOptions.format[format] || format;
    const dateObject = parseISODate(timestamp);

    return formatCustomDate(dateObject, dateFormat, { locale: localeOptions.locale });
}

/**
 * Formats a given timestamp to a locale-specific date string using timezone.
 * If the timestamp is falsy, returns null.
 *
 * @param timestamp - The timestamp to format.
 * @param locale - The locale to use for formatting.
 * @param format - Preset name used for formatting or custom format string
 * @returns The formatted date string, or null if the timestamp is falsy.
 */
export function formatUsingTimeZone(utcDate: Date, timezone: string, locale: string, format: string = 'full') {
    const localeOptions = LOCALES_TIME_FORMAT_MAP[locale] || LOCALES_TIME_FORMAT_MAP[LOCALE_DEFAULT];
    const dateFormat = localeOptions.format[format] || format;
    return formatCustomDate(utcDate, dateFormat, {
        in: tz(timezone),
        locale: localeOptions.locale,
    });
}

export function getLinksToAvailableLocales(options: LinkOptions) {
    const { localeList, localesConfig, urlTemplate } = options;

    return localeList
        .map((locale) => localesConfig.find((config) => config.isoCode === locale))
        .filter(Boolean)
        .map((config) => {
            const to = urlTemplate.replace('{{LOCALE}}', config.isoCode);
            const text = config.language;
            return { to, text, locale: config.isoCode };
        });
}
