import { render, staticRenderFns } from "./demo-modal.vue?vue&type=template&id=266a0141&scoped=true"
import script from "./demo-modal.vue?vue&type=script&lang=js"
export * from "./demo-modal.vue?vue&type=script&lang=js"
import style0 from "./demo-modal.vue?vue&type=style&index=0&id=266a0141&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266a0141",
  null
  
)

export default component.exports