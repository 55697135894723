// Explanation on web-vitals abbreviations https://web.dev/vitals/
// NB: this script is client-only
import { onINP, onLCP, onFCP, onTTFB, onCLS } from 'web-vitals';
import { Logger } from './logger';

export function sendBrowserMetrics(context: any) {
    const watchDog = new Set();

    const namesMap = {
        INP: 'interaction-to-next-paint',
        LCP: 'largest-contentful-paint',
        FCP: 'first-contentful-paint',
        TTFB: 'time-to-first-byte',
        CLS: 'cumulative-layout-shift',
    };

    const report = ({ name, value }) => {
        const readableName = namesMap[name] || name;
        const endpoint = `${context.public.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_SERVICE_API_CLIENT}/svc/v1/metrics/latency/${readableName}`;
        const payload = JSON.stringify({ latency: value });
        const opts = { type: 'application/json' };
        const blob = new Blob([payload], opts);

        const sendBeacon = navigator.sendBeacon.bind(navigator);
        sendBeacon(endpoint, blob);
    };

    const reportOnce = ({ name, value }) => {
        if (watchDog.has(name)) {
            return;
        }

        watchDog.add(name);
        report({ name, value });
    };

    try {
        (window.metrics || []).forEach(reportOnce);

        onINP(report, { reportAllChanges: true });
        onLCP(reportOnce);
        onFCP(reportOnce);
        onTTFB(reportOnce);
        onCLS(report, { reportAllChanges: true });
    } catch (error) {
        Logger.error({ error });
    }
}
