import { mapState } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    computed: {
        ...mapState({
            productItems: (state) => state.products.items,
            productId: (state) => state.pages?.page.product_id,
            currency: (state) => state.locales?.locale?.currency || 'USD',
            currencyFormat: (state) => state.locales?.locale?.currency_format || '12,345,678.91',
        }),

        productCurrency() {
            return this.productItems?.[this.productID]?.parameters?.common?.currency || this.currency;
        },
    },

    methods: {
        setCurrency(value, settings) {
            const product = this.productItems[settings?.productId || this.productId];
            const currencyFormat = settings?.format || product?.parameters?.common?.currency_format;
            const productCurrencyFormat = currencyFormat || this.currencyFormat;
            const matches = productCurrencyFormat.match(
                /^([^\d\s]*)(\s*)(\d+)([^\d])?(\d{3})([^\d])?(\d{3})(([^\d])(\d+))?(\s*)([^\d\s]*)$/,
            );

            const price = !value && settings?.zeroByDefault ? 0 : parseFloat(value);
            if (!settings?.zeroByDefault && (!matches || price <= 0)) {
                return '???';
            }

            const currency = {
                symbol: matches[1] ? matches[1] : matches[12],
                space: matches[1] ? matches[2] : matches[11],
                position: matches[1] ? 'before' : 'after',
                decimalsDrop: !matches[8],
                decimalsCustom: matches[10] ? matches[10].length : 0,
                decimalDelimiter: matches[9] ? matches[9] : '.',
                groupDelimiter: matches[4] && matches[6] && matches[4] === matches[6] ? matches[4] : '',
            };

            const source = String(
                currency.decimalsDrop ? Math.ceil(price) : price.toFixed(currency.decimalsCustom),
            );
            const integer = source.split('.')[0];
            const groupsCount = integer.length > 3 ? integer.length % 3 : 0;
            let result = groupsCount ? integer.substr(0, groupsCount) + currency.groupDelimiter : '';
            result += integer.substr(groupsCount).replace(/(\d{3})(?=\d)/g, `$1${currency.groupDelimiter}`);

            const fraction = source.split('.')[1] || 0;
            result += !currency.decimalsDrop ? currency.decimalDelimiter + fraction : '';

            if (settings && settings.withoutCurrencySymbol) {
                currency.symbol = '';
            }

            currency.space = currency.space ? ' ' : '';
            if (currency.position === 'before') {
                result = currency.symbol + currency.space + result;
            } else {
                result += currency.space + currency.symbol;
            }

            return result
                .replace(/(&euro;|&pound;|&yen;)/g, '<sup>$1</sup>')
                .replaceAll(' ', '&nbsp;')
                .replace(/\$&/g, '&dollar;&'); // $& inserts the matched substring
        },
    },

    async serverPrefetch() {
        await this.$store.dispatch('locales/getLocaleData', { locale: this.$route.params.locale || LOCALE_DEFAULT });
    },
};
