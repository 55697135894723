import { mapGetters } from 'vuex';
import { SITE_ID_TRUEIMAGE } from '@model/const/sites.ts';

// TI site requires its own slice-setting content, see https://pmc.acronis.work/browse/WEB-47984.
// Extracted to a mixin because this decision is needed in two slices:
// s-locale-selector and s-locale-selector-ribbon

export default {
    computed: {
        ...mapGetters('config', ['$config']),

        localeSelectorDataSourceName() {
            switch (this.$config.siteID) {
                case SITE_ID_TRUEIMAGE:
                    return 's-locale-selector-true-image';
                default:
                    return 's-locale-selector';
            }
        },
    },
};
