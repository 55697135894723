import MicroModal from 'micromodal';
import commonUtils from '@utils/common.ts';

class Modal {
    constructor(el, id, vnode) {
        this.el = el;
        this.id = id;
        this.vnode = vnode;
        this.events = {};
        this.startTime = null;

        if (this.el && this.id) {
            this.openModal = this.openModal.bind(this);
            this.init();
        }
    }

    init() {
        this.el.addEventListener('click', this.openModal);
        this.el.addEventListener('keypress', this.openModal);
    }

    openModal(e) {
        e.preventDefault();
        MicroModal.show(this.id, {
            openClass: 'is-modal-open',
            disableFocus: true,
            onShow: (modal) => {
                const closeButtons = modal.querySelectorAll('[data-close]');
                const youtube = modal.querySelector('.a-youtube');
                const video = modal.querySelector('.a-modal__video-player');
                const audio = modal.querySelector('.a-modal__audio-player');

                try {
                    this.startTime = new Date();
                    this.events = JSON.parse(localStorage.getItem('modalEvents'));
                    localStorage.removeItem('modalEvents');
                    this.sendEvent(this.events.open);
                } catch { /* empty */ }

                if (closeButtons.length > 0) {
                    closeButtons.forEach((element) => element.addEventListener('click', this.closeModal));
                }
                if (video) video.play();
                if (audio) audio.play();

                if (youtube) {
                    const ytBaseUrl = (youtube.dataset.src || '').split('?')[0];
                    this.sendEvent({
                        event: 'Acronis',
                        eventAction: 'Video',
                        eventContext: 'Play',
                        eventCategory: 'Interactions',
                        eventLabel: youtube.dataset.title || ytBaseUrl,
                    });
                    this.toggleIframe(modal, 'playVideo');
                }
                /* If you don't use timer here, it will fire onClose before the onShow when using gallery/playlist modals
                * Causing it to enable the scroll again, both with commonUtils and witht he native disableScroll function
                */
                setTimeout(() => {
                    commonUtils.globalOverflowProperty('hidden');
                });
            },
            onClose: (modal) => {
                if (this.events?.close) {
                    const endTime = new Date();
                    const timeOnDemoText = (s) => {
                        if (s < 10) return '0–10sec';
                        if (s < 30) return '11–30sec';
                        if (s < 60) return '31–60sec';
                        if (s < 180) return '1-3mins';
                        if (s < 300) return '3-5mins';
                        if (s < 600) return '5-10mins';
                        if (s < 900) return '10-15mins';
                        if (s < 1200) return '15-20mins';
                        if (s < 1800) return '20-30mins';
                        return '30+ minutes';
                    };
                    this.sendEvent({
                        eventContext: timeOnDemoText((endTime - this.startTime) / 1000),
                        ...this.events.close,
                    });
                }

                const closeButton = modal.querySelector('[data-close]');
                const video = modal.querySelector('.a-modal__video-player');
                const audio = modal.querySelector('.a-modal__audio-player');

                if (closeButton) {
                    closeButton.removeEventListener('click', this.closeModal);
                }

                if (video) {
                    video.pause();
                    video.currentTime = 0;
                }

                if (audio) {
                    audio.pause();
                    audio.currentTime = 0;
                }

                this.toggleIframe(modal, 'pauseVideo');
                this.emit('close', this.el);
                commonUtils.globalOverflowProperty('auto');
            },
        });
    }

    closeModal(e) {
        const id = e.target.closest('.a-modal').id;
        e.preventDefault();
        e.stopPropagation();
        MicroModal.close(id);
    }

    toggleIframe(modal, func) {
        const youtube = modal.querySelector('.a-youtube');

        if (!youtube) {
            return false;
        }

        if (!youtube.src) {
            youtube.src = youtube.dataset.src;
        }

        youtube.contentWindow.postMessage(`{"event":"command","func":"${func}","args":""}`, '*');
        return true;
    }

    sendEvent(event) {
        if (typeof event !== 'object') return;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

    emit(name, data) {
        const handlers = this.vnode.data?.on || this.vnode.componentOptions?.listeners;
        handlers?.[name]?.fns(data);
    }

    destroy() {
        this.el.removeEventListener('click', this.openModal);
        this.el.removeEventListener('keypress', this.openModal);
    }
}

export default {
    inserted(el, { value }, vnode) {
        el.modal = new Modal(el, value, vnode);
    },

    unbind(el) {
        if (el.modal) {
            el.modal.destroy();
            el.modal = null;
        }
    },
};
