// TODO: tidy this up
import { uniqBy } from 'lodash';

export default {
    GA_TRACKING_ID: 'UA-149943-47',
    MRKTO_COOKIE_NAME: '_mkto_trk',
    UID_COOKIE_NAME: 'AcronisUID.en',
    MRKTO_TOKEN_PART: 'id:929-HVV-335&token:_mch-acronis.com-',
    COOKIE_AGE: 60 * 60 * 24 * 30,

    createMarketoToken() {
        const marketoToken = `${this.MRKTO_TOKEN_PART}${new Date().getTime()}-${Math.floor(9e4 * Math.random()) + 1e4}`;
        document.cookie = `${this.MRKTO_COOKIE_NAME}=${marketoToken};max-age=${this.COOKIE_AGE}; path=/`;

        return marketoToken;
    },

    getCookies(name) {
        const values = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
        return values ? values.pop() : '';
    },

    getGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            /* eslint-disable no-bitwise */
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
            /* eslint-enable no-bitwise */
        });
    },

    stringify(obj) {
        return Object.keys(obj)
            .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
            .join('&');
    },

    sendDataLayer(data) {
        window.dataLayer = window.dataLayer || [];

        const result = {
            event: 'Acronis',
            eventAction: 'click',
            eventCategory: 'form',
            eventContent: '',
            eventContext: '',
        };

        // TODO: any reason to not merge `result` and `data` with `...` operator?
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(data)) {
            result[key] = value;
        }
        window.dataLayer.push(result);
    },

    getCIDval() {
        let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        const raw = match ? decodeURIComponent(match[1]) : null;
        if (raw) {
            match = raw.match(/(\d+\.\d+)$/);
        }
        return match ? match[1] : null;
    },

    checkIfEmailHasAddressSign(email) {
        return /@/g.test(email);
    },

    checkIfEmailValid(email) {
        // A message to anyone who wants to "improve" this method with some monstrous mulitline regexp:
        // PLEASE read this first: https://david-gilbertson.medium.com/the-100-correct-way-to-validate-email-addresses-7c4818f24643

        // Now when you're done: we can't send an activation email, so we will do just a _minimal_ validation
        // But for a reason stated above we have to invert the logic
        // - no whitespace characters excluding leading/traling space
        const normalized = (email || '').trim();
        if (normalized.match(/\s/)) return false;
        // - there is exactly one `@` symbol
        const segments = normalized.split('@');
        if (segments.length !== 2) return false;
        // - there is at least one character before `@`
        if (!segments[0].length) return false;
        // - there is something which looks like a domain after `@symbol`
        if (!segments[1].includes('.') || segments[1].startsWith('.') || segments[1].endsWith('.')) return false;
        // - ok then
        return true;
    },

    checkIfPhoneValid(phone) {
        if (phone.replace(/[^0-9]/g, '').length < 8) {
            return false;
        }
        return /^\+?[\s|\d|(|)|\-|–]*$/.test(phone);
    },

    checkIfFieldLengthValid(type, value) {
        const limits = {
            input: 255,
            textarea: 65535,
        };
        const size = value?.length || 0;
        return !limits[type] || limits[type] >= size;
    },

    getUniqueDataCentersForPlatform(allDataCenters = [], platform = 'acronis') {
        const filtered = allDataCenters.filter((e) => e.platform === platform && e.marketoCode);
        let dataCenters = uniqBy(filtered, 'marketoCode');

        const dataCentersPerCountry = dataCenters.reduce((a, e) => {
            const dataCentersNumber = { ...a };
            dataCentersNumber[e.country] = ++dataCentersNumber[e.country] || 1;
            return dataCentersNumber;
        }, {});

        // add city name to country field if we have multiple DCs per country
        dataCenters = dataCenters.map((e) => {
            if (dataCentersPerCountry[e.country] === 1) return e;

            return { ...e, country: `${e.country}, ${e.city}` };
        });

        return dataCenters.sort((a, b) => a.country.localeCompare(b.country));
    },

    setDataCenter(dataCenters = [], countryCode = '') {
        const result = dataCenters.find((item) => item.marketoCountry === countryCode) || {};
        return result.marketoCode || '';
    },

    sfdcCampaignSmart() {
        // WEB-38750
        let result = {};
        const utmContentSession = window.sessionStorage
            ? sessionStorage.getItem('utm_content')
            : '';
        const utmContent = decodeURIComponent(utmContentSession).split(':');

        if (['sfdc', 'smart_sfdc', 'Smart_sfdc'].includes(utmContent[0])) {
            const url = window.location.href;
            const pageList = [
                ['contact-sales', 'products/cloud/trial', 'products/cloud/express-signup'],
                ['products/cyber-protect/trial', 'products/backup/trial'],
            ];

            pageList.forEach((list, i) => {
                if (utmContent[i + 1]) {
                    if (list.some((str) => url.includes(str))) {
                        result = { sfdc_campaign_id: utmContent[i + 1] };
                    }
                }
            });
        }

        return result;
    },

    sfdcCampaign(list) {
        const result = {};
        const sfdc = [
            { name: 'SFDCCampaignID', value: 'sfdc_campaign_id' },
            { name: 'SFDCCampaignStatus', value: 'sfdc_campaign_status' },
        ];
        const searchParams = new URL(window.location.href).searchParams;
        sfdc.forEach((item) => {
            const value = searchParams.get(item.name);
            result[item.value] = value || list?.[item.value] || null;
        });
        return result;
    },

    labelsUTM(utm) {
        if (!window.sessionStorage) return {};

        const result = {};
        const labels = [
            'utm_campaign',
            'utm_content',
            'utm_medium',
            'utm_source',
            'utm_term',
        ];
        labels.forEach((label) => {
            const value = sessionStorage.getItem(label);
            result[label] = value || utm?.[label] || null;
        });
        return result;
    },

    labelsUTMCleverbridge() {
        if (!window.sessionStorage) return {};

        const result = {};
        const labels = [
            'utm_campaign',
            'utm_content',
            'utm_medium',
            'utm_source',
            'utm_term',
        ];
        labels.forEach((name) => {
            const value = sessionStorage.getItem(name);
            result[`x-${name}`] = value;
        });
        result['x-conversion_page'] = window.location.href;
        return result;
    },

    getSecondsOnPage() {
        if (typeof performance !== 'undefined') {
            return Math.ceil(performance.now() / 1000);
        }
        return 0;
    },
};
